/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, meta, title, image,
}) {
  const { site } = useStaticQuery(
    graphql`
			query {
				site {
					siteMetadata {
						defaultTitle: title
						defaultDescription: description
						author
						defaultImage: image
					}
				}
			}
		`,
  );

  const { defaultTitle, defaultDescription, defaultImage } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
  };

  return (
    <Helmet
      htmlAttributes={{
			  lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
			  {
			    name: 'description',
			    content: seo.description,
			  },
			  {
			    property: 'og:title',
			    content: seo.title,
			  },
			  {
			    property: 'og:description',
			    content: seo.description,
			  },
			  {
			    property: 'og:image',
			    content: seo.image,
			  },
			  {
			    property: 'og:image:width',
			    content: '1200',
			  },
			  {
			    property: 'og:image:height',
			    content: '630',
			  },
			  {
			    property: 'og:image:type',
			    content: 'image/png',
			  },
			  {
			    property: 'og:image:url',
			    content: seo.image,
			  },
			  {
			    property: 'og:image:alt',
			    content: 'Skynox',
			  },
			  {
			    property: 'og:type',
			    content: 'website',
			  },
			  {
			    name: 'twitter:card',
			    content: 'summary_large_image',
			  },
			  {
			    name: 'twitter:creator',
			    content: site.siteMetadata.author,
			  },
			  {
			    name: 'twitter:title',
			    content: title,
			  },
			  {
			    name: 'twitter:image',
			    content: seo.image,
			  },
			  {
			    name: 'twitter:image:alt',
			    content: 'Skynox',
			  },
			  {
			    name: 'twitter:description',
			    content: seo.description,
			  },
      ].concat(meta)}
    >
      {' '}
      <script async src="https://cdn.splitbee.io/sb.js" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
